var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              ref: "formFilter",
              staticClass: "m-0",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-row justify-content-start" },
                [
                  _c("b-col", { attrs: { cols: "12", md: "1" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.id,
                            expression: "filters.id",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", placeholder: "ID" },
                        domProps: { value: _vm.filters.id },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.filters, "id", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "4" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "Período",
                            config: {
                              mode: "range",
                              altInput: true,
                              dateFormat: "Y-m-d",
                              altFormat: "d/m/Y",
                              locale: "pt",
                            },
                          },
                          model: {
                            value: _vm.filters.rangeDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "rangeDate", $$v)
                            },
                            expression: "filters.rangeDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "1" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm.submitedFilter
                          ? _c(
                              "div",
                              [
                                _c("b-spinner", {
                                  attrs: { label: "Loading...", small: "" },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "SearchIcon", size: "16" },
                                }),
                              ],
                              1
                            ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.submitedFilter
        ? _c(
            "b-card",
            { staticClass: "mb-0", attrs: { "no-body": "" } },
            [
              _c(
                "div",
                { staticClass: "m-2" },
                [
                  _c(
                    "b-form-row",
                    { staticClass: "justify-content-between" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-none d-sm-block align-items-center justify-content-left",
                          attrs: { cols: "4", md: "2" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "title",
                              required: "",
                              options: _vm.limitOptions,
                              clearable: false,
                            },
                            on: { input: _vm.setlimitSelected },
                            model: {
                              value: _vm.filters.limit,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "limit", $$v)
                              },
                              expression: "filters.limit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4", offset: "md-4" } },
                        [
                          _c("b-form-input", {
                            staticClass: "d-inline-block",
                            attrs: {
                              type: "search",
                              placeholder: "Pesquisar...",
                              id: "filterInput",
                            },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.$can("Repasse - Reprovar", "Comercial")
                    ? _c(
                        "div",
                        { staticClass: "form-row justify-content-end my-2" },
                        [
                          _c("div", { staticClass: "col-md-2" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm btn-warning btn-block",
                                attrs: {
                                  disabled:
                                    _vm.selectedItems.length < 1 ? true : false,
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteRemittances()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "bi bi-hand-thumbs-down cursor-pointer cursor mr-1",
                                  staticStyle: {
                                    "font-size": "1.2rem",
                                    "line-height": "1.5",
                                  },
                                }),
                                _vm._v(" Reprovar "),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-2" }, [
                            _vm.$can(
                              "Repasse - Aprovar como Financeiro",
                              "Comercial"
                            )
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-success btn-block",
                                    attrs: {
                                      disabled:
                                        _vm.selectedItems.length < 1
                                          ? true
                                          : false,
                                      type: "button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.approveRemittances()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-hand-thumbs-up cursor-pointer cursor mr-1",
                                      staticStyle: {
                                        "font-size": "1.2rem",
                                        "line-height": "1.5",
                                      },
                                    }),
                                    _vm._v(" Aprovar "),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("b-table", {
                staticClass: "position-relative",
                attrs: {
                  items: _vm.dataItems,
                  responsive: "",
                  fields: _vm.fields,
                  "sticky-header": "",
                  "primary-key": "id",
                  "show-empty": "",
                  "empty-text": "Nenhum registro encontrado",
                  filter: _vm.filter,
                  "filter-included-fields": _vm.filterOn,
                },
                on: { filtered: _vm.onFiltered },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head(select)",
                      fn: function () {
                        return [
                          _c("b-form-checkbox", {
                            on: { change: _vm.toggleSelectAll },
                            model: {
                              value: _vm.selectAllCheckbox,
                              callback: function ($$v) {
                                _vm.selectAllCheckbox = $$v
                              },
                              expression: "selectAllCheckbox",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "cell(select)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("b-form-checkbox", {
                            staticStyle: {
                              "margin-left": "13px",
                              padding: "0px",
                              width: "20px",
                              margin: "auto",
                            },
                            attrs: { value: item.id },
                            model: {
                              value: _vm.selectedItems,
                              callback: function ($$v) {
                                _vm.selectedItems = $$v
                              },
                              expression: "selectedItems",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "cell(status)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "span",
                            {
                              style:
                                "color: " +
                                item.status.color_hex +
                                ";padding: 7px 10px; border-radius: 5px; display: block; align-items: center; align-content: center;",
                            },
                            [_vm._v(" " + _vm._s(item.status.name) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(attachments)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("i", { staticClass: "bi bi-paperclip" }),
                          _vm._v(" " + _vm._s(item.medias.length || 0) + " "),
                        ]
                      },
                    },
                    {
                      key: "cell(orders)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "strong",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  value: "Quantidade de produtos na matrícula.",
                                  expression:
                                    "'Quantidade de produtos na matrícula.'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass: "text-purple",
                            },
                            [
                              _c("i", { staticClass: "bi bi-box" }),
                              _vm._v(
                                " " + _vm._s(item.orders.length || 0) + " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(created_at)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .moment(item.created_at)
                                  .format("DD/MM/YYYY HH:mm")
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(remittance_date)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.remittance_date
                                  ? _vm
                                      .moment(item.remittance_date)
                                      .format("DD/MM/YYYY")
                                  : "-"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(action)",
                      fn: function (data) {
                        return [
                          _c(
                            "feather-icon",
                            {
                              staticClass: "cursor-pointer cursor",
                              attrs: { icon: "EyeIcon", size: "16" },
                              on: {
                                click: function ($event) {
                                  return _vm.openModal(data.item)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "bi bi-eye" }),
                              _vm._v(" Visualizar "),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1581055947
                ),
              }),
              _c("CustomPaginateTable", {
                attrs: {
                  "rows-table": _vm.rowsTable,
                  "current-page": _vm.filters.page,
                  "per-page": _vm.filters.limit,
                  "total-rows": _vm.totalRows,
                },
              }),
            ],
            1
          )
        : _c("b-card", { staticClass: "mb-0" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12 text-center" }, [
                _c(
                  "p",
                  { staticClass: "my-0 py-3 d-flex-center" },
                  [
                    _c("b-spinner", {
                      staticClass: "mr-50",
                      attrs: { small: "", variant: "primary" },
                    }),
                    _vm._v(" Buscando repasses... "),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
      _vm.selectedRemittance
        ? _c("RemittancePreview", {
            attrs: {
              show: _vm.modalVisible,
              remittance: _vm.selectedRemittance,
            },
            on: {
              close: function ($event) {
                _vm.modalVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }